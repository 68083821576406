import { navigate as gatsbyNavigate } from "gatsby"

export enum Routes {
  home = "/",
  administrators = "/for-administrators/",
  teachers = "/for-teachers/",
  families = "/families/",
  schools = "/schedule-demo/",
  schoolsSuccess = "/schools-success",
  features = "/how-yup-works/",
  learningFundamentals = "/teaching-framework/",
  mathTutoring = "/subjects-we-teach/",
  ourTutors = "/our-tutors/",
  privacy = "/privacy/",
  legal = "/legal/",
  blog = "/blog/",
  resources = "/videos-and-media/",
  press = "/press/",
  about = "/about/",
  faq = "/faq/",
  familyFaq = "/faq-for-families/",
  tutors = "/for-tutors/",
  careers = "/careers/",
  contact = "/contact/",
  reviews = "/reviews/",
  plans = "/plans/", // Change to /plans/?env=staging1 to test
  purchase = "/purchase",
  reviewPurchase = "/review-purchase",
  purchaseComplete = "/purchase-complete",
  mathResources = "/math-resource-pages/",
  downloads = "/downloads/",
  tutorOnboarding = "/tutor-onboarding",
  highDosageTutoring = "/school-day-integrations/",
  app = "/app",
  sendme = "/sendme",
  teacherHub = "/teacher-hub",
  teacherAnnouncements = "/teacher-announcements",
  calendly = "/demo/#calendly",
  watchDemo = "/demo/",
  watchWebinar = "/webinar-recording/",
  watchWebinarMarch = "/webinar-recording-march-2022/",
}

export enum ExternalRoutes {
  helpCenter = "https://help.prenda.com/knowledge/yup",
}

export function isInternalRoute(route: string) {
  return (
    route?.startsWith("#") ||
    Object.values(Routes).some(r => route?.startsWith(r))
  )
}

export function navigate(route: string) {
  if (typeof window !== "undefined") gatsbyNavigate(route)
}
